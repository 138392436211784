import {gql, graphql} from 'react-apollo';
import {compose} from 'redux';
import reject from 'lodash/reject';

import loadingSpinner from '../../shared/hoc/loadingSpinner';
import FilterPanel from '../../shared/components/list_view/FilterPanel';

const FILTER_QUERY = gql`
  query EquipmentFilterMetaQuery {
    equipmentsFilterMeta {
      name
      label
      type
      picklist
    }
  }
`;

const container = compose(
  graphql(FILTER_QUERY, {
    props: ({data: {loading, error, equipmentsFilterMeta}}) => ({
      loading,
      error,
      metadata: reject(equipmentsFilterMeta || [], {type: 'Lookup'}) // excluding lookups like in tickets
    })
  }),
  loadingSpinner
);

export default container(FilterPanel);
