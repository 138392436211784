import React from 'react';
import Field from 'redux-form/lib/Field';
import styled from 'styled-components';
import Spinner from '../Spinner';
import Alert from '../Alert';
import Select from '../inputs/Select';
import Input from '../inputs/Input';
import Toggle from '../inputs/Toggle';
import ClearButton from '../ClearButton';
import Glyphicon from '../Glyphicon';
import {ModalPanel, Overlay} from '../styles';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';

// a way for users to persist a view (column / filter / sort selection)
const SavedView = ({
  savedViews,
  useView,
  setSelectedView,
  handleSubmit,
  error,
  submitting,
  isSavePanelOpen,
  selectedView,
  deleteView,
  deleteError,
  deleting,
  isLoadPanelOpen,
  closePanel,
  openSavePanel,
  openLoadPanel,
  intl
}) => (
  <StyleContainer>
    <div>
      <ClearButton onClick={openSavePanel}>
        <Glyphicon glyph="floppy-disk" />
        &nbsp; <FormattedMessage id="shared.saved_view_floppy" />
      </ClearButton>
      <ClearButton onClick={openLoadPanel}>
        <Glyphicon glyph="bookmark" />
        &nbsp; <FormattedMessage id="shared.saved_view_bookmark" />
      </ClearButton>
    </div>
    <ModalPanel show={isSavePanelOpen}>
      <form className="form-horizontal">
        {submitting && (
          <Spinner
            loadingText={intl.formatMessage({
              id: 'shared.saved_view_submitting'
            })}
          />
        )}
        <Field
          component={Input}
          label={intl.formatMessage({id: 'shared.saved_view_horizontal'})}
          horizontal
          name="name"
        />
        <Field
          component={Toggle}
          label={intl.formatMessage({
            id: 'shared.saved_view_modal_include_filters'
          })}
          horizontal
          name="includeFilters"
        />
        <Alert error={error} />
        <div>
          <ClearButton onClick={handleSubmit}>
            <Glyphicon glyph="floppy-disk" />
            &nbsp;<FormattedMessage id="shared.save" />
          </ClearButton>
          <ClearButton onClick={closePanel}>
            <Glyphicon glyph="remove" />
            &nbsp;<FormattedMessage id="shared.saved_view_cancel" />
          </ClearButton>
        </div>
      </form>
    </ModalPanel>
    <ModalPanel show={isLoadPanelOpen}>
      <form className="form-horizontal">
        {deleting && (
          <Spinner
            loadingText={intl.formatMessage({
              id: 'shared.saved_view_deleting'
            })}
          />
        )}
        <Select
          label={intl.formatMessage({id: 'shared.saved_view_saved'})}
          horizontal
          value={selectedView}
          options={savedViews.map(v => ({label: v.name, value: v.name}))}
          onChange={setSelectedView}
        />
        <Alert error={deleteError} />
        <div>
          {selectedView && (
            <ClearButton onClick={useView}>
              <Glyphicon glyph="list" />
              &nbsp;<FormattedMessage id="shared.saved_view_list" />
            </ClearButton>
          )}
          {selectedView && (
            <ClearButton onClick={deleteView}>
              <Glyphicon glyph="trash" />
              &nbsp;<FormattedMessage id="shared.saved_view_delete" />
            </ClearButton>
          )}
          <ClearButton onClick={closePanel}>
            <Glyphicon glyph="remove" />
            &nbsp;<FormattedMessage id="shared.saved_view_close" />
          </ClearButton>
        </div>
      </form>
    </ModalPanel>
    <Overlay show={isLoadPanelOpen || isSavePanelOpen} />
  </StyleContainer>
);

SavedView.propTypes = {
  savedViews: PropTypes.array.isRequired,
  useView: PropTypes.func.isRequired,
  setSelectedView: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  isSavePanelOpen: PropTypes.bool.isRequired,
  selectedView: PropTypes.string,
  deleteView: PropTypes.func.isRequired,
  deleteError: PropTypes.string,
  deleting: PropTypes.bool.isRequired,
  isLoadPanelOpen: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired,
  openSavePanel: PropTypes.func.isRequired,
  openLoadPanel: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
};

const StyleContainer = styled.div`
  text-align: right;

  form {
    background: white;
    padding: 10px;
    border-radius: 4px;
  }
`;

export default SavedView;
