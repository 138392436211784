import {gql, graphql} from 'react-apollo';
import {compose} from 'redux';
import {withHandlers, withProps} from 'recompose';
import ReactDOM from 'react-dom';
import {injectIntl} from 'react-intl';

import loadingSpinner from '../../shared/hoc/loadingSpinner';
import ExportPanel from '../../shared/components/list_view/ExportPanel';
import savedViewContainer from '../../shared/components/list_view/SavedViewContainer';
const META_QUERY = gql`
  query EquipmentFieldMetaQuery {
    equipmentsFieldMeta(excludeSubProperties: true) {
      name
      label
    }
  }
`;

// scroll position HACK
// make sure we have this outside of state, since state will be lost once component remounts
let scrollPosition = 0;

export default compose(
  injectIntl,
  graphql(META_QUERY, {
    props: ({data: {loading, error, equipmentsFieldMeta}}) => ({
      loading,
      error,
      metadata: equipmentsFieldMeta && equipmentsFieldMeta
    })
  }),
  loadingSpinner,
  withHandlers({
    exportList: ({selectedFields, filter, sort}) => e => {
      const fields = Object.keys(selectedFields);
      window.open(
        '/api/export/equipment?' +
          `filter=${JSON.stringify(filter)}&sort=${JSON.stringify(
            sort
          )}&fields=${JSON.stringify(fields)}`
      );
    },
    selectAllFields: ({metadata, selectAllFields}) => e => {
      selectAllFields(metadata.map(f => f.name));
    }
  }),

  // handling for scroll
  withHandlers({
    scrollingPanelRef: () => ref => {
      if (ref) {
        const element = ReactDOM.findDOMNode(ref);
        element.addEventListener('scroll', e => {
          scrollPosition = e.target.scrollTop;
        });
        if (scrollPosition) {
          element.scrollTop = scrollPosition;
        }
      }
    }
  }),
  withProps(({intl, fieldSelectionOnly, metadata}) => ({
    helpText:
      (fieldSelectionOnly
        ? intl.formatMessage({
            id: 'equipments.export_panel.select_columns_you_would_like'
          })
        : intl.formatMessage({
            id: 'equipments.export_panel.select_fields_you_would_like'
          })) +
      intl.formatMessage({
        id: 'equipments.export_panel.to_save_your_selection'
      }),
    title: fieldSelectionOnly
      ? intl.formatMessage({id: 'equipments.export_panel.select_columns_title'})
      : intl.formatMessage({
          id: 'equipments.export_panel.download_equipment_title'
        }),
    metadata:
      metadata && metadata.filter(m => !m.name.startsWith('VendorContact'))
  })),
  savedViewContainer('equipments')
)(ExportPanel);
