import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import './App.css';
import SignInPage from './auth/containers/SignInPageContainer';
import SignOutPage from './auth/containers/SignOutPageContainer';
import TicketInsert from './tickets/pages/TicketInsert';
import ComingSoonPage from './shared/pages/ComingSoonPage';
import FullTicketList from './tickets/pages/FullTicketList';
import FullEquipmentList from './equipments/pages/FullEquipmentList';
import EquipmentDetailPage from './equipments/containers/EquipmentDetailContainer';
import TicketDetailPage from './tickets/containers/TicketDetailContainer';
import ContactPage from './contact/pages/ContactPage';
import VideoPage from './videos/pages/VideoPage';
import appPage from './shared/hoc/appPage';
import dashboardPage from './shared/hoc/dashboardPage';
import Landing from './dashboard/pages/Landing';

/**
 * Top level routes for the application.
 *
 * appPage is a decorator that includes a getCurrentUser query to pass the user info
 * (and check the login validity)
 * If they are not currently logged in, they will be immediately redirected to the login screen.
 */
export default () => (
  <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
    <Switch>
      <Route path="/login" component={SignInPage} />
      <Route path="/logout" component={SignOutPage} />
      <Route path="/faq" component={appPage(ComingSoonPage)} />
      <Route path="/tickets/new-ticket" component={appPage(TicketInsert)} />
      <Route path="/tickets/:view" component={appPage(FullTicketList)} />
      <Route path="/tickets" component={appPage(FullTicketList)} />
      <Route path="/equipments" component={appPage(FullEquipmentList)} />
      <Route
        path="/equipment/:equipmentId"
        component={appPage(EquipmentDetailPage)}
      />
      <Route path="/contact" component={appPage(ContactPage)} />
      <Route path="/videos" component={appPage(VideoPage)} />
      <Route path="/ticket/:ticketId" component={appPage(TicketDetailPage)} />
      <Route path="" component={dashboardPage(Landing)} />
    </Switch>
  </BrowserRouter>
);
