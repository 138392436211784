import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import get from 'lodash/get';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

import ApplicationPage from '../../shared/pages/ApplicationPage';
import DetailSection from '../components/DetailSection';
// import TicketActivitySection from '../components/TicketActivitySection';
import TicketActivityInput from '../containers/TicketActivityInputContainer';
import TicketAttachmentSection from '../containers/TicketAttachmentContainer';
import TicketTimeline from '../components/TicketTimeline';
import {Back} from '../../shared/icons';
import ManageSelfPerformedContainer from '../containers/ManageSelfPerformedContainer';
import ApproveEstimateContainer from '../containers/ApproveEstimateContainer';
import TicketEditContainer from '../containers/TicketEditContainer';

import {thinBorder} from '../../constants';
import needApproval from '../utils/needApproval';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: 0
    }
  },
  top: {
    flexBasis: '100%'
  },
  detail: {
    flexGrow: 1,
    flexBasis: '30%'
  },
  back: {
    display: 'flex',
    alignItems: 'center'
  },
  timeline: {
    flexGrow: 2,
    flexBasis: '60%',
    // borderLeft: thinBorder,
    marginLeft: '30px',
    paddingLeft: '30px',
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
      marginLeft: 0,
      paddingLeft: 0,
      marginTop: 30
    }
  }
}));

const TicketDetail = ({ticket, currentUser, fieldMetadata, match, intl}) => {
  const classes = useStyles();
  const tz = get(ticket, 'Account.Address.TimeZone');

  return (
    <ApplicationPage currentUser={currentUser}>
      <div className={classes.container}>
        <section className={classes.top}>
          {/* these are at the top so the errors show better */}
          {currentUser.permissions.manageSelfPerformTicket && (
            <Route
              path={`${match.url}/manage-self-performed`}
              render={({history}) => (
                <ManageSelfPerformedContainer
                  history={history}
                  ticket={ticket}
                  fieldMetadata={fieldMetadata}
                />
              )}
            />
          )}
          {needApproval(ticket) && (
            <Route
              path={`${match.url}/approve-estimate`}
              render={({history}) => (
                <ApproveEstimateContainer
                  history={history}
                  ticket={ticket}
                  fieldMetadata={fieldMetadata}
                />
              )}
            />
          )}
          {ticket.permissions.editTicket && (
            <Route
              path={`${match.url}/edit`}
              render={({history}) => (
                <TicketEditContainer
                  currentUser={currentUser}
                  history={history}
                  ticket={ticket}
                  fieldMetadata={fieldMetadata}
                />
              )}
            />
          )}
          <h1>
            <FormattedMessage id="details" />
          </h1>
          <hr />
        </section>

        <section className={classes.detail}>
          <Link to={'/tickets'} className={classes.back}>
            <Back /> Back to Work Orders
          </Link>
          <DetailSection
            ticket={ticket}
            fieldMetadata={fieldMetadata}
            urlRoot={match.url}
            permissions={currentUser.permissions}
            toolbar={detailsToolbar(
              ticket.permissions.editTicket,
              needApproval(ticket),
              intl
            )}
          />
          {ticket.permissions.addActivity ? (
            <TicketActivityInput
              currentUser={currentUser}
              ticketId={ticket.id}
              timeZone={tz}
              urlRoot={match.url}
              needApproval={needApproval(ticket)}
            />
          ) : null}
          <TicketAttachmentSection
            ticketId={ticket.id}
            timeZone={tz}
            attachments={ticket.Attachments}
            urlRoot={match.url}
            readOnly={!ticket.permissions.addActivity}
          />
        </section>
        <div className={classes.timeline}>
          <TicketTimeline
            timeZone={tz}
            timeline={ticket.Timeline}
            intl={intl}
          />
        </div>
      </div>
    </ApplicationPage>
  );
};

const detailsToolbar = (editTicket, needApproval, intl) => {
  const tools = [];
  if (editTicket) {
    tools.push({
      label: intl.formatMessage({id: 'tickets.edit_work_order'}),
      icon: 'pencil',
      path: 'edit'
    });
  }
  if (needApproval) {
    tools.push({
      label: intl.formatMessage({id: 'tickets.approve_estimate'}),
      icon: 'ok',
      path: 'approve-estimate',
      btnClass: 'btn-success',
      showLabel: true
    });
  }
  return tools;
};

TicketDetail.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.string.isRequired,
    permissions: PropTypes.shape({
      editTicket: PropTypes.bool.isRequired,
      addActivity: PropTypes.bool.isRequired
    }).isRequired,
    Timeline: PropTypes.array.isRequired,
    Attachments: PropTypes.array.isRequired,
    Account: PropTypes.shape({
      Address: PropTypes.shape({
        TimeZone: PropTypes.string
      })
    })
  }).isRequired,
  fieldMetadata: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  currentUser: PropTypes.shape({
    permissions: PropTypes.shape({
      manageSelfPerformTicket: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

export default TicketDetail;

// vim ft=javascript.jsx
