import React from 'react';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {lifecycle, withProps, branch, compose} from 'recompose';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import update from 'immutability-helper';
import {injectIntl} from 'react-intl';

import {
  EQUIPMENT_LIST_QUERY,
  EQUIPMENT_LIST_VARIABLES,
  FIELDMETA_QUERY
} from '../queries';
import * as listActions from '../actions/list';
import EquipmentList from '../components/EquipmentList.js';
import errorAlert from '../../shared/hoc/errorAlert';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import {isFilterEmpty} from '../../shared/utils/filterUtils';
import makeGraphqlSelect from '../../utils/makeGraphqlSelect.js';
import mapFieldMetadata from '../../utils/mapFieldMetadata';

let componentCache = null;
let componentCacheFields = null;

const graphqlContainer = ChildComponent => props => {
  const fields =
    props.querySelectedFields &&
    makeGraphqlSelect(Object.keys(props.selectedFields));

  if (componentCache && componentCacheFields === fields) {
    return React.createElement(componentCache, props);
  }

  const query = EQUIPMENT_LIST_QUERY(fields);
  const Component = graphql(query, {
    options: ({filter, page, sort, search}) => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: EQUIPMENT_LIST_VARIABLES({
        filter,
        sort,
        search,
        page
      })
    }),
    props({data: {loading, error, fetchMore, equipmentsList = {}}}) {
      const {equipments = [], totalCount = 0} = equipmentsList;
      return {
        loading,
        error,
        records: equipments,
        totalCount,
        loadMoreRows() {
          if (totalCount <= equipments.length) {
            return Promise.resolve();
          }
          return fetchMore({
            variables: {
              offset: equipment.length
            },
            updateQuery(previousResult, {fetchMoreResult}) {
              if (!fetchMoreResult) return previousResult;
              const {
                equipmentList: {equipment}
              } = fetchMoreResult;
              return update(previousResult, {
                equipmentList: {
                  equipment: {$push: equipment},
                  totalCount: {$set: totalCount}
                }
              });
            }
          });
        }
      };
    }
  })(ChildComponent);

  componentCache = Component;
  componentCacheFields = fields;

  return React.createElement(Component, props);
};

const metadataContainer = compose(
  graphql(FIELDMETA_QUERY, {
    props: ({
      ownProps: {routeFilter},
      data: {loading, error, equipmentsFieldMeta, equipmentsDefaultFilter}
    }) => ({
      loading,
      error,
      fieldMetadata: mapFieldMetadata(equipmentsFieldMeta),
      defaultFilter: routeFilter
    })
  }),
  loadingSpinner
);

const defaultFilterContainer = compose(
  lifecycle({
    _setDefaultFilter(defaultFilter) {
      const filter = (defaultFilter || []).map(({field, value}) => ({
        field: field,
        value: isArray(value) ? {in: value} : {eq: value}
      }));
      this.props.replaceFilter(filter);
    },
    componentDidMount() {
      if (this.props.defaultFilter && this.props.defaultFilter.length) {
        this._setDefaultFilter(this.props.defaultFilter);
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.defaultFilter !== this.props.defaultFilter) {
        this._setDefaultFilter(this.props.defaultFilter);
      }
    }
  })
);

const redirectToHome = () => props => {
  console.log('No permission to view equipment, redirect to home');
  return (
    <Redirect
      to={{
        pathname: '/'
      }}
    />
  );
};

const requireViewEquipmentPermission = branch(
  ({currentUser}) => currentUser && !currentUser.permissions.viewEquipments,
  redirectToHome
);

const container = compose(
  requireViewEquipmentPermission,
  injectIntl,
  connect(
    (state, {intl}) => ({
      page: state.equipments.list.page,
      search: state.equipments.list.search,
      sort: state.equipments.list.sort,
      filter: state.equipments.list.filter,
      selectedFields: state.equipments.list.selectedFields,
      isFilterExpanded: state.equipments.list.filterExpanded,
      isSortExpanded: state.equipments.list.sortExpanded,
      isExportExpanded: state.equipments.list.exportExpanded,
      isFieldSelectionExpanded: state.equipments.list.fieldSelectionExpanded,
      title: intl.formatMessage({id: 'equipments.equipment_list'}),
      i18nPrefix: 'equipments'
    }),
    listActions
  ),
  withProps(({filter}) => ({
    hasEmptyFilter: some(filter, isFilterEmpty),
    isFilterActive: some(filter, f => !isFilterEmpty(f))
  })),
  metadataContainer,
  defaultFilterContainer,
  graphqlContainer,
  errorAlert
);

export default container(EquipmentList);
