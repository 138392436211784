import {withRouter} from 'react-router-dom';
import {compose, withProps, withHandlers, withStateHandlers} from 'recompose';
import EquipmentGrid from '../components/EquipmentGrid';
import {PAGE_LENGTH} from '../../constants';

export default compose(
  withRouter,
  withStateHandlers(
    {
      currentPage: 1
    },
    {
      goToNextPage: (
        {currentPage},
        {records, totalCount, loadMoreRows}
      ) => () => {
        currentPage++;
        if (
          currentPage * PAGE_LENGTH > records.length &&
          records.length < totalCount
        ) {
          loadMoreRows();
        }
        return {currentPage};
      }
    }
  ),
  withProps(
    ({fieldMetadata, records, totalCount, currentPage, selectedFields}) => ({
      columns: makeColumns(Object.keys(selectedFields), fieldMetadata),
      records: records,
      hasNext: currentPage * PAGE_LENGTH < totalCount
    })
  ),
  withHandlers({
    goToRecord: ({history}) => id => history.push('/equipment/' + id)
  })
)(EquipmentGrid);

const makeColumns = (cols, metadata) =>
  cols.map(col => metadata[col] || null).filter(x => !!x);
