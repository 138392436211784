import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ReactSelect from '../inputs/ReactSelect';
import ClearButton from '../ClearButton';
import Glyphicon from '../Glyphicon';
import {FormattedMessage} from 'react-intl';

const SortPanel = ({
  sort,
  setSortField,
  toggleSortDirection,
  toggleSort,
  metadata,
  i18nPrefix
}) => (
  <div className="panel panel-default panel-sort">
    <div className="panel-heading">
      <h3 className="panel-title">
        <FormattedMessage id={`${i18nPrefix}.sortpanel_heading`} />
      </h3>
    </div>
    <div className="panel-body">
      <div className="row no-gutters">
        <div className="col-xs-12 col-sm-8">
          <ReactSelect
            clearable={false}
            options={makeOptions(metadata)}
            value={sort.on}
            onChange={setSortField}
          />
        </div>
        <div className="col-xs-12 col-sm-4">
          <DirButton className="btn btn-default" onClick={toggleSortDirection}>
            <Glyphicon
              glyph={
                sort.dir === 'desc'
                  ? 'sort-by-alphabet-alt'
                  : 'sort-by-alphabet'
              }
            />
            {sort.dir === 'desc' ? 'Desc.' : 'Asc.'}
          </DirButton>
        </div>
      </div>
    </div>
    <div className="panel-footer text-right">
      <ClearButton onClick={toggleSort}>
        <Glyphicon glyph="remove" />
        &nbsp;Close
      </ClearButton>
    </div>
  </div>
);

const makeOptions = metadata =>
  metadata.map(m => ({label: m.name, value: m.name}));

// little bit taller, to match the react-select
const DirButton = styled.button`
  height: 36px;
`;

SortPanel.propTypes = {
  sort: PropTypes.object.isRequired,
  setSortField: PropTypes.func.isRequired,
  toggleSortDirection: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
  metadata: PropTypes.array.isRequired,
  i18nPrefix: PropTypes.string.isRequired
};

export default SortPanel;
