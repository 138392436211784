import React from 'react';
import PropTypes from 'prop-types';

import ClearButton from '../ClearButton';
import Glyphicon from '../Glyphicon';
import FilterControl from './FilterControl';
import {FormattedMessage} from 'react-intl';

// top level for filter panel
const FilterPanel = ({
  filter,
  addFilterRow,
  className,
  hasEmptyFilter,
  isFilterActive,
  clearFilter,
  toggleFilter,
  intl,
  i18nPrefix,
  ...props
}) => (
  <div className={className}>
    <div className="panel panel-default panel-filter">
      <div className="panel-heading">
        <h3 className="panel-title">
          <FormattedMessage id={`${i18nPrefix}.filterpanel_title`} />
        </h3>
      </div>
      <div className="panel-body">
        {filter.map((f, i) => (
          <FilterControl
            key={i}
            field={f.field}
            value={f.value}
            index={i}
            {...props}
          />
        ))}
      </div>
      <div className="panel-footer text-right">
        {isFilterActive && (
          <ClearButton onClick={clearFilter}>
            <Glyphicon glyph="erase" />
            &nbsp;Reset
          </ClearButton>
        )}
        {!hasEmptyFilter && (
          <ClearButton onClick={addFilterRow}>
            <Glyphicon glyph="plus" />
            &nbsp;More...
          </ClearButton>
        )}
        <ClearButton onClick={toggleFilter}>
          <Glyphicon glyph="remove" />
          &nbsp;Close
        </ClearButton>
      </div>
    </div>
  </div>
);

FilterPanel.propTypes = {
  addFilterRow: PropTypes.func.isRequired,
  filter: PropTypes.array.isRequired,
  metadata: PropTypes.array.isRequired,
  clearFilter: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  i18nPrefix: PropTypes.string.isRequired
};

export default FilterPanel;

// vim ft=javascript.jsx
