import React from 'react';
import PropTypes from 'prop-types';
import {Hidden} from '@material-ui/core';
import DataTable from '../../shared/components/list_view/DataTable';
import MobileWorkOrders from './MobileWorkOrders';
import Spinner from '../../shared/components/Spinner';

const TicketGrid = props => {
  const {loading, columns, records} = props;
  return (
    <React.Fragment>
      {loading && <Spinner />}
      <Hidden mdDown>
        <DataTable header={columns} rows={records} {...props} />
      </Hidden>
      <Hidden lgUp>
        <MobileWorkOrders rows={records} {...props} />
      </Hidden>
    </React.Fragment>
  );
};

TicketGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  // height: PropTypes.number.isRequired,
  records: PropTypes.array.isRequired,
  hasNext: PropTypes.bool.isRequired,
  goToNextPage: PropTypes.func.isRequired
};

export default TicketGrid;
