import {gql} from 'react-apollo';

export const CURRENT_USER_QUERY = gql`
  query {
    currentUser {
      WebUserName
      NameLF
      ContactId
      id
      Account {
        id
        AccountName
        Type
      }
      defaultStore {
        label
        value
      }
      ParentAccount {
        id
        BannerConfig {
          Type
          Url
          Message
          IsBottom
          IsHomeScreen
          IsNewWorkOrderScreen
        }
        ProgramBook
        PortalInstructions
      }
      ParentContact {
        CrmId
        NameLF
      }
      managedAccounts {
        id
        AccountName
      }
      permissions {
        createStore
        manageSelfPerformTicket
        doNotDefaultRequestedByName
        createTicket
        createTicketLink
        viewContacts
        viewDashboard
        viewTickets
        viewTickets_ReceivedDate
        viewVideos
        viewSpendByStore
        viewEquipments
      }
    }
  }
`;
