import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {
  OutlinedInput,
  InputAdornment,
  Hidden
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import CurrentFilters from './CurrentFilters';
import Glyphicon from '../Glyphicon';
import {ThreeStateButton} from '../styles';

const useStyles = makeStyles(theme => ({
  header: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 0 0 1rem'
    },
    h4: {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 120px)',
      padding: '2px 0',
      margin: '7px 0',
      overflow: 'hidden'
    },
    '.btn-group': {
      marginTop: '2px'
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60
  },
  button: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  inactiveTitle: {
    padding: '1px 4.5px 1px 5.5px',
    borderRadius: 1.5,
    fontFamily: 'Nunito',
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#686b6b'
  },
  activeTitle: {
    padding: '1px 4.5px 1px 5.5px',
    borderRadius: 1.5,
    fontFamily: 'Nunito',
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#a6192e',
    borderBottom: 'solid #a6192e'
  },
  inactiveNumber: {
    padding: 4.5,
    borderRadius: 3,
    backgroundColor: '#e6e6e6',
    fontFamily: 'Nunito',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#686b6b',
    width: 30,
    marginLeft: 5
  },
  activeNumber: {
    backgroundImage: 'linear-gradient(to bottom, #e4002b -26%, #a6192e 132%)',
    padding: 4.5,
    borderRadius: 3,
    fontFamily: 'Nunito',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#ffffff',
    width: 30,
    marginLeft: 5
  }
}));
const inputStyles = makeStyles(theme => ({
  root: {
    borderRadius: 25,
    width: '100%',
    height: 30,
    [theme.breakpoints.up('sm')]: {
      width: 200
    },
    marginBottom: theme.spacing(1)
  }
}));

// top of the list
const ListHeader = ({
  totalCount,
  loading,
  toggleFilter,
  toggleSort,
  toggleExport,
  toggleFieldSelection,
  intl,
  isFilterActive,
  isFilterExpanded,
  isSortExpanded,
  isExportExpanded,
  isFieldSelectionExpanded,
  title,
  filter,
  search,
  setSearch,
  fieldMetadata,
  removeFilterRow,
  querySelectedFields,
  i18nPrefix = 'tickets'
}) => {
  const classes = useStyles();
  const inputClasses = inputStyles();
  const [state, setState] = useState('all');
  const handleActive = active => event => {
    setState(active);
  };
  const setSearchDebounced = debounce(setSearch, 500);

  return (
    <header className={classes.header}>
      {/*
      The top header, with the options for "My Work Orders", etc.  It's not working right now, need
      some more work on the backend, so we don't display it just yet.  Maybe later.

      <Hidden smDown>
        <Paper className={classes.paper}>
          <ButtonBase className={classes.button} onClick={handleActive('all')}>
            <span
              className={clsx(classes.inactiveTitle, {
                [classes.activeTitle]: state === 'all'
              })}>
              All
            </span>
            <span
              className={clsx(classes.inactiveNumber, {
                [classes.activeNumber]: state === 'all'
              })}>
              95
            </span>
          </ButtonBase>
          <ButtonBase
            className={classes.button}
            onClick={handleActive('my-work-orders')}>
            <span
              className={clsx(classes.inactiveTitle, {
                [classes.activeTitle]: state === 'my-work-orders'
              })}>
              My Work Orders
            </span>
            <span
              className={clsx(classes.inactiveNumber, {
                [classes.activeNumber]: state === 'my-work-orders'
              })}>
              14
            </span>
          </ButtonBase>
          <ButtonBase
            className={classes.button}
            onClick={handleActive('my-proposal')}>
            <span
              className={clsx(classes.inactiveTitle, {
                [classes.activeTitle]: state === 'my-proposal'
              })}>
              My Proposal{' '}
            </span>
            <span
              className={clsx(classes.inactiveNumber, {
                [classes.activeNumber]: state === 'my-proposal'
              })}>
              14
            </span>
          </ButtonBase>
        </Paper>
      </Hidden>
      */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 15,
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <OutlinedInput
          placeholder={intl.formatMessage({id: 'action.search'})}
          onChange={e => setSearchDebounced(e.target.value)}
          classes={inputClasses}
          endAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Hidden smDown>
          <div
            className="btn-group pull-right"
            style={{marginTop: 20, marginBottom: 20}}>
            <ThreeStateButton
              className="btn"
              onClick={toggleFilter}
              active={isFilterActive}
              expanded={isFilterExpanded}
              title={intl.formatMessage({
                id: isFilterActive
                  ? `${i18nPrefix}.filter_is_active`
                  : `${i18nPrefix}.filter`
              })}
              aria-label={intl.formatMessage({
                id: isFilterActive
                  ? `${i18nPrefix}.filter_is_active`
                  : `${i18nPrefix}.filter`
              })}>
              <Glyphicon glyph="filter" />
            </ThreeStateButton>
            <ThreeStateButton
              className="btn"
              onClick={toggleExport}
              expanded={isExportExpanded}
              title={intl.formatMessage({
                id: `${i18nPrefix}.download`
              })}
              aria-label={intl.formatMessage({
                id: `${i18nPrefix}.aria_download`
              })}>
              <Glyphicon glyph="save" />
            </ThreeStateButton>
            {querySelectedFields && (
              <ThreeStateButton
                className="btn"
                onClick={toggleFieldSelection}
                expanded={isFieldSelectionExpanded}
                title={intl.formatMessage({
                  id: `${i18nPrefix}.select_columns`
                })}
                aria-label={intl.formatMessage({
                  id: `${i18nPrefix}.aria_select_columns`
                })}>
                <Glyphicon glyph="list" />
              </ThreeStateButton>
            )}
          </div>
        </Hidden>
      </div>
      <CurrentFilters
        filter={filter}
        fieldMetadata={fieldMetadata}
        removeFilterRow={removeFilterRow}
      />
    </header>
  );
};

ListHeader.propTypes = {
  totalCount: PropTypes.number.isRequired,
  isFilterExpanded: PropTypes.bool.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  isFieldSelectionExpanded: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  toggleFieldSelection: PropTypes.func.isRequired,
  querySelectedFields: PropTypes.bool,
  isSortExpanded: PropTypes.bool.isRequired,
  toggleSort: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  filter: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  fieldMetadata: PropTypes.object.isRequired,
  // "tickets" or "equipments"
  i18nPrefix: PropTypes.string
};

export default injectIntl(ListHeader);

// vim ft=javascript.jsx
