import React from 'react';
import PropTypes from 'prop-types';
import {
  Cleaning,
  HVAC,
  Landscaping,
  Painting,
  Plumbing,
  Electrical,
  Handyman,
  KeysLock,
  OtherIcon
} from '../../shared/icons';

export const TRADE_ICONS = {
  HVAC: props => <HVAC {...props} />,
  Plumbing: props => <Plumbing {...props} />,
  Cleaning: props => <Cleaning {...props} />,
  Painting: props => <Painting {...props} />,
  Landscaping: props => <Landscaping {...props} />,
  Handyman: props => <Handyman {...props} />,
  Electrical: props => <Electrical {...props} />,
  Lock: props => <KeysLock {...props} />,
  Other: props => <OtherIcon {...props} />
};

const TradeIcon = ({trade, ...props}) => {
  const Icon = TRADE_ICONS[trade] || TRADE_ICONS.Other;
  return <Icon {...props} />;
};

TradeIcon.propTypes = {
  trade: PropTypes.string.isRequired
};

export default TradeIcon;
