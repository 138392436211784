import {graphql, compose} from 'react-apollo';
import {withProps} from 'recompose';
import {injectIntl} from 'react-intl';

import errorIfNotFound from '../../shared/hoc/errorIfNotFound';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import mapFieldMetadata from '../../utils/mapFieldMetadata';
import EquipmentDetail from '../pages/EquipmentDetail.js';
import {EQUIPMENT_DETAIL_QUERY} from '../queries';

// Container for the Equipment Detail page
const container = compose(
  injectIntl,
  graphql(EQUIPMENT_DETAIL_QUERY, {
    options: ({match}) => ({
      variables: {
        equipmentId: match.params.equipmentId
      }
    }),
    props: ({data}) => ({
      loading: data.loading,
      error: data.error,
      equipment: data.equipment,
      fieldMetadata: mapFieldMetadata(data.equipmentsFieldMeta)
    })
  }),
  // TODO make sure this does not show over the entire page
  loadingSpinner,
  errorIfNotFound('equipment'),
  withProps(({equipmentsPermissions}) => ({}))
);

export default container(EquipmentDetail);
