// container for the ticket list page
import React from 'react';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {lifecycle, withProps, branch, compose} from 'recompose';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import update from 'immutability-helper';
import {injectIntl} from 'react-intl';

import {
  TICKET_LIST_QUERY,
  TICKET_LIST_VARIABLES,
  FIELDMETA_QUERY
} from '../queries';
import * as listActions from '../actions/list';
import TicketList from '../components/TicketList.js';
import errorAlert from '../../shared/hoc/errorAlert';
import loadingSpinner from '../../shared/hoc/loadingSpinner';
import {isFilterEmpty} from '../../shared/utils/filterUtils.js';
import makeGraphqlSelect from '../../utils/makeGraphqlSelect.js';
import mapFieldMetadata from '../../utils/mapFieldMetadata';

let componentCache = null;
let componentCacheFields = null;
// container for the ticket list query.
// this has an extra level of indirection because the query can be modified dynamically, according to the fields selected.
const graphqlContainer = ChildComponent => props => {
  // querySelectedFields is passed as true to indicate that we need to use selectedFields to drive the query field selection
  const fields =
    props.querySelectedFields &&
    makeGraphqlSelect(Object.keys(props.selectedFields));
  if (componentCache && componentCacheFields === fields)
    return React.createElement(componentCache, props);

  const query = TICKET_LIST_QUERY(fields);
  const Component = graphql(query, {
    options: ({filter, page, sort, search}) => ({
      notifyOnNetworkStatusChange: true,
      // will ensure that we refresh the query after inserting a new ticket
      fetchPolicy: 'network-only',
      variables: TICKET_LIST_VARIABLES({
        filter,
        sort,
        search,
        page
      })
    }),
    // extract relevant props from data and flatten it into props
    // add a loadMoreRows method for pagination
    props({data: {loading, error, fetchMore, ticketsList = {}}}) {
      const {tickets = [], totalCount = 0} = ticketsList;
      // console.log('error', error)
      // console.log('loading', loading)
      // console.log('tickets', tickets)
      return {
        loading,
        error,
        records: tickets,
        totalCount,
        loadMoreRows() {
          if (totalCount <= tickets.length) {
            // console.log('loadMoreRows called, but no more rows are available')
            return Promise.resolve();
          }
          return fetchMore({
            variables: {
              offset: tickets.length
            },
            updateQuery(previousResult, {fetchMoreResult}) {
              if (!fetchMoreResult) return previousResult;
              const {
                ticketsList: {tickets}
              } = fetchMoreResult;
              return update(previousResult, {
                ticketsList: {
                  tickets: {$push: tickets},
                  totalCount: {$set: totalCount}
                }
              });
            }
          });
        }
      };
    } // end props
  })(ChildComponent); // end graphql
  componentCache = Component;
  componentCacheFields = fields;

  return React.createElement(Component, props);
};

// metadata needed on the grid and on the "Current Filters" display
const metadataContainer = compose(
  graphql(FIELDMETA_QUERY, {
    props: ({
      ownProps: {routeFilter},
      data: {loading, error, ticketsFieldMeta, ticketsDefaultFilter}
    }) => ({
      loading,
      error,
      fieldMetadata: mapFieldMetadata(ticketsFieldMeta),
      defaultFilter: routeFilter
    })
  }),
  loadingSpinner
);

const defaultFilterContainer = compose(
  lifecycle({
    _setDefaultFilter(defaultFilter) {
      const filter = (defaultFilter || []).map(({field, value}) => ({
        field: field,
        value: isArray(value) ? {in: value} : {eq: value}
      }));
      this.props.replaceFilter(filter);
    },
    componentDidMount() {
      if (this.props.defaultFilter && this.props.defaultFilter.length) {
        this._setDefaultFilter(this.props.defaultFilter);
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.defaultFilter !== this.props.defaultFilter) {
        this._setDefaultFilter(this.props.defaultFilter);
      }
    }
  })
);

const redirectToHome = () => props => {
  console.log('No permission to view tickets, redirect to home');
  return (
    <Redirect
      to={{
        pathname: '/'
      }}
    />
  );
};

const requireViewTicketsPermission = branch(
  ({currentUser}) => currentUser && !currentUser.permissions.viewTickets,
  redirectToHome
);

const container = compose(
  requireViewTicketsPermission,
  injectIntl,
  connect(
    (state, {intl}) => ({
      page: state.tickets.list.page,
      search: state.tickets.list.search,
      sort: state.tickets.list.sort,
      filter: state.tickets.list.filter,
      selectedFields: state.tickets.list.selectedFields,
      isFilterExpanded: state.tickets.list.filterExpanded,
      isSortExpanded: state.tickets.list.sortExpanded,
      isExportExpanded: state.tickets.list.exportExpanded,
      isFieldSelectionExpanded: state.tickets.list.fieldSelectionExpanded,
      title:
        state.tickets.list.title ||
        intl.formatMessage({id: 'tickets.work_order'}),
      i18nPrefix: 'tickets'
    }),
    listActions
  ),
  withProps(({filter}) => ({
    // do we have an empty row in filter?
    hasEmptyFilter: some(filter, isFilterEmpty),
    // is there a filter currently?
    isFilterActive: some(filter, f => !isFilterEmpty(f))
  })),
  // withStateHandlers(
  //   {calcHeight: 0},
  //   {
  //     calcHeightRef: () => ref => {
  //       // console.log('calcHeightRef', ref && ref.offsetHeight)
  //       if (ref) {
  //         let h = ref.offsetHeight;
  //         if (h > window.innerHeight) h = window.innerHeight;
  //         return {calcHeight: h};
  //       }
  //     }
  //   }
  // ),
  metadataContainer,
  defaultFilterContainer,
  graphqlContainer,
  errorAlert
);

export default container(TicketList);
