import React from 'react';
import PropTypes from 'prop-types';
import {lighten, makeStyles, withStyles} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper
} from '@material-ui/core';
import get from 'lodash/get';
import {withRouter} from 'react-router-dom';
import {PAGE_LENGTH} from '../../../constants';
import formatTime from '../../../utils/formatTime';
import formatDate from '../../../utils/formatDate';

const EnhancedTableHead = props => {
  const {classes, order, orderBy, rowCount, onRequestSort, data} = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const labelClasses = labelStyles();
  return (
    <TableHead>
      <TableRow>
        {data.map(headCell => (
          <TableCell
            key={headCell.name}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.label ? order : false}>
            <TableSortLabel
              classes={labelClasses}
              active={orderBy === headCell.name}
              direction={orderBy === headCell.name ? order : 'asc'}
              onClick={createSortHandler(headCell.name)}
              style={{
                color: '#a6192e',
                fontSize: '1.4rem'
              }}>
              {headCell.label}
              {orderBy === headCell.name ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));
const labelStyles = makeStyles(theme => ({
  root: {
    color: '#a6192e',
    fontSize: '1.4rem',
    '&:svg': {
      color: '#a6192e'
    }
  }
}));

function EnhancedTable(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = props.sort.on === property && props.sort.dir === 'asc';
    props.setSort({
      on: property,
      dir: isAsc ? 'desc' : 'asc'
    });
  };

  const {header: headerData, rows: rowData, goToRecord} = props;
  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Paper>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                orderBy={props.sort.on}
                order={props.sort.dir}
                onRequestSort={handleRequestSort}
                rowCount={headerData.length}
                data={headerData}
              />
              <TableBody>
                {rowData.map((row, index) => {
                  return (
                    <RenderRecord
                      key={`rr_${row.id}`}
                      columns={headerData}
                      record={row}
                      handleClick={goToRecord}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={props.totalCount}
            rowsPerPage={PAGE_LENGTH}
            page={props.page}
            onChangePage={(e, p) => props.setPage(p)}
          />
        </Paper>
      </div>
    </div>
  );
}

const RenderRecord = ({columns, record, handleClick}) => {
  return (
    <TableRow
      hover
      role="checkbox"
      onClick={e => handleClick(record.id)}
      tabIndex={-1}
      key={record.id}>
      {columns.map(column => (
        <TableCell key={column.name} style={{cursor: 'pointer'}}>
          {formatValue(get(record, column.name), column.type)}
        </TableCell>
      ))}
    </TableRow>
  );
};

RenderRecord.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string
    })
  ).isRequired,
  record: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  handleClick: PropTypes.func.isRequired
};

function formatValue(val, type) {
  if (!val) return val;
  switch (type) {
    case 'DateTime':
      return formatTime(val);
    case 'Date':
      return formatDate(val);
    default:
      return val;
  }
}

EnhancedTable.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      type: PropTypes.string
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  sort: PropTypes.shape({
    on: PropTypes.string.isRequired,
    dir: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  setSort: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  goToRecord: PropTypes.func.isRequired
};

export default withRouter(EnhancedTable);
