import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';

import {Paper} from '@material-ui/core';
import OutputField from '../../shared/components/OutputField';
import {styles} from '../../shared/components/styles';

/*
 * <DetailSection/>
 * Main equipment detail section
 * If adding some fields here, remember to add them to the detail query in ../queries.js
 */
const DetailSection = ({equipment, fieldMetadata, classes}) => {
  return (
    <Paper elevation={3} className={classes.paperCard} style={{padding: 20}}>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <OutputField
            record={equipment}
            meta={fieldMetadata}
            field="Description"
          />
          <OutputField
            record={equipment}
            meta={fieldMetadata}
            field="EquipmentType"
          />
          <OutputField record={equipment} meta={fieldMetadata} field="Make" />
          <OutputField record={equipment} meta={fieldMetadata} field="Model" />
          <OutputField
            record={equipment}
            meta={fieldMetadata}
            field="SerialNumber"
          />
        </div>
        <div className="col-xs-12 col-md-6">
          {fieldMetadata.StoreHeader && (
            <h6>{fieldMetadata.StoreHeader.label}</h6>
          )}
          <OutputField
            record={equipment}
            meta={fieldMetadata}
            field="StoreNumber"
          />
          <OutputField
            record={equipment}
            meta={fieldMetadata}
            field="StoreAddressCity"
          />
          <OutputField
            record={equipment}
            meta={fieldMetadata}
            field="StoreAddressState"
          />
          <OutputField
            record={equipment}
            meta={fieldMetadata}
            field="StoreAddressZip"
          />
        </div>
      </div>
    </Paper>
  );
};

DetailSection.propTypes = {
  equipment: PropTypes.object.isRequired,
  fieldMetadata: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  injectIntl
)(DetailSection);
