import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import clsx from 'clsx';

import ListHeader from '../../shared/components/list_view/ListHeader';
import EquipmentGridContainer from '../containers/EquipmentGridContainer';
import SortPanelContainer from '../containers/SortPanelContainer';
import FilterPanelContainer from '../containers/FilterPanelContainer';
import ExportPanelContainer from '../containers/ExportPanelContainer';
import {
  PositioningContainer,
  ModalPanel,
  Overlay
} from '../../shared/components/styles';

class EquipmentList extends React.Component {
  render() {
    const {
      isFilterExpanded,
      isSortExpanded,
      isExportExpanded,
      isFieldSelectionExpanded
    } = this.props;
    const props = this.props;

    return (
      <Section className={clsx(props.className, 'equipment-list')}>
        <ListHeader {...props} />
        <PositioningContainer>
          <ModalPanel
            show={isFilterExpanded}
            onClick={e => e.stopPropagation()}>
            {isFilterExpanded && <FilterPanelContainer {...props} />}
          </ModalPanel>
          <ModalPanel show={isSortExpanded} onClick={e => e.stopPropagation()}>
            {isSortExpanded && <SortPanelContainer {...props} />}
          </ModalPanel>
          <ModalPanel
            show={isExportExpanded}
            onClick={e => e.stopPropagation()}>
            {isExportExpanded && (
              <ExportPanelContainer fieldSelectionOnly={false} {...props} />
            )}
          </ModalPanel>
          <ModalPanel
            show={isFieldSelectionExpanded}
            onClick={e => e.stopPropagation()}>
            {isFieldSelectionExpanded && (
              <ExportPanelContainer fieldSelectionOnly {...props} />
            )}
          </ModalPanel>
          <Overlay
            show={
              isFilterExpanded ||
              isSortExpanded ||
              isExportExpanded ||
              isFieldSelectionExpanded
            }
          />
          <div className="list-container">
            <EquipmentGridContainer {...props} />
          </div>
        </PositioningContainer>
      </Section>
    );
  }
}

const Section = styled.section`
  .spinner-container {
    z-index: 15;
  }
  display: flex;
  flex-direction: column;
  height: 100vh;
  & > header {
    flex: 0 0 auto;
  }
  & > div {
    flex: 1;
    .list-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }
  }
`;

EquipmentList.propTypes = {
  totalCount: PropTypes.number.isRequired,
  records: PropTypes.array.isRequired,
  isFilterExpanded: PropTypes.bool.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  isExportExpanded: PropTypes.bool.isRequired,
  isSortExpanded: PropTypes.bool.isRequired,
  isFieldSelectionExpanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  i18nPrefix: PropTypes.string.isRequired
};

export default EquipmentList;
