import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

import ApplicationPage from '../../shared/pages/ApplicationPage';
import DetailSection from '../components/DetailSection';
import {Back} from '../../shared/icons';
import EquipmentTicketsSection from '../components/EquipmentTicketsSection';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: 0
    }
  },
  top: {
    flexBasis: '100%'
  },
  detail: {
    flexGrow: 1,
    flexBasis: '30%'
  },
  back: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const EquipmentDetail = ({equipment, currentUser, fieldMetadata}) => {
  const classes = useStyles();

  return (
    <ApplicationPage currentUser={currentUser}>
      <div className={classes.container}>
        <section className={classes.detail}>
          <Link to={'/equipments'} className={classes.back}>
            <Back /> <FormattedMessage id="equipments.back_to_equipments" />
          </Link>
          <DetailSection equipment={equipment} fieldMetadata={fieldMetadata} />
          <EquipmentTicketsSection
            tickets={equipment.Tickets}
            currentUser={currentUser}
          />
        </section>
      </div>
    </ApplicationPage>
  );
};

EquipmentDetail.propTypes = {
  equipment: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  fieldMetadata: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default EquipmentDetail;

// vim ft=javascript.jsx
