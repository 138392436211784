export const LIST_TOGGLE_FILTER = 'EQUIPMENT:LIST_TOGGLE_FILTER';
export const LIST_SET_FILTER_FIELD = 'EQUIPMENT:LIST_SET_FILTER_FIELD';
export const LIST_SET_FILTER_VALUE = 'EQUIPMENT:LIST_SET_FILTER_VALUE';
export const LIST_REPLACE_FILTER = 'EQUIPMENT:LIST_REPLACE_FILTER ';
export const LIST_ADD_FILTER_ROW = 'EQUIPMENT:LIST_ADD_FILTER_ROW';
export const LIST_REMOVE_FILTER_ROW = 'EQUIPMENT:LIST_REMOVE_FILTER_ROW';
export const LIST_CLEAR_FILTER = 'EQUIPMENT:LIST_CLEAR_FILTER';
export const LIST_TOGGLE_SORT = 'EQUIPMENT:LIST_TOGGLE_SORT';
export const LIST_SET_PAGE = 'EQUIPMENT:LIST_SET_PAGE';
export const LIST_SET_SEARCH = 'EQUIPMENT:LIST_SET_SEARCH';
export const LIST_SET_SORT = 'EQUIPMENT:LIST_SET_SORT';
export const LIST_SET_VIEW = 'EQUIPMENT:LIST_SET_VIEW';
export const LIST_TOGGLE_EXPORT = 'EQUIPMENT:LIST_TOGGLE_EXPORT';
export const LIST_TOGGLE_FIELDSELECTION =
  'EQUIPMENT:LIST_TOGGLE_FIELDSELECTION';
export const LIST_SELECT_FIELD = 'EQUIPMENT:LIST_SELECT_FIELD';
export const LIST_UNSELECT_FIELD = 'EQUIPMENT:LIST_UNSELECT_FIELD';
export const LIST_SELECT_DEFAULT_FIELDS =
  'EQUIPMENT:LIST_SELECT_DEFAULT_FIELDS';
export const LIST_SELECT_ALL_FIELDS = 'EQUIPMENT:LIST_SELECT_ALL_FIELDS';
export const LIST_UNSELECT_ALL_FIELDS = 'EQUIPMENT:LIST_UNSELECT_ALL_FIELDS';
export const LIST_CLOSE_PANELS = 'EQUIPMENT:LIST_CLOSE_PANELS';

export const ATTACHMENT_UPLOAD_START = 'EQUIPMENT:ATTACHMENT_UPLOAD_START';
export const ATTACHMENT_UPLOAD_ERROR = 'EQUIPMENT:ATTACHMENT_UPLOAD_ERROR';
export const ATTACHMENT_UPLOAD_COMPLETE =
  'EQUIPMENT:ATTACHMENT_UPLOAD_COMPLETE';
