import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {isFilterEmpty, getFilterDisplay} from '../../utils/filterUtils';
import Glyphicon from '../Glyphicon';
import ClearButton from '../ClearButton';
import {makeStyles} from '@material-ui/core/styles';

const OneFilter = ({label, value, removeFilter, classes}) => (
  <li className={clsx(classes.li, 'label', 'label-default')}>
    <label>{label}:</label>
    <span>{getFilterDisplay(value)}</span>
    <ClearButton onClick={removeFilter}>
      <Glyphicon glyph="remove" />
    </ClearButton>
  </li>
);

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0
  },

  li: {
    margin: '0 0.5rem 0.5rem 0',
    display: 'inline-block',
    whiteSpace: 'normal',

    '& > label': {
      padding: '0 0.5rem 0 0'
    },
    '& > span': {
      fontWeight: 'normal'
    },
    '& > .btn': {
      padding: '0',
      fontSize: '100%'
    }
  }
}));
const CurrentFilters = ({filter, fieldMetadata, removeFilterRow}) => {
  const classes = useStyles();

  return (
    <ul className={classes.container}>
      {filter.map(({field, value}, index) => {
        if (isFilterEmpty({field, value})) return null;
        return (
          <OneFilter
            classes={classes}
            key={index}
            label={fieldMetadata[field].label}
            value={value}
            removeFilter={() => removeFilterRow(index)}
          />
        );
      })}
    </ul>
  );
};
CurrentFilters.propTypes = {
  filter: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.object.isRequired
    })
  ).isRequired,
  fieldMetadata: PropTypes.object.isRequired,
  removeFilterRow: PropTypes.func.isRequired
};

export default CurrentFilters;
