import {handleActions} from 'redux-actions';

import {ROOT_CLICK} from '../../shared/actions/types';
import * as types from '../actions/types';
import update from 'immutability-helper';
import {addEmptyRow} from '../../shared/utils/filterUtils';
import makeSet from 'f1-js-utils/makeSet';

const defaultState = {
  filter: addEmptyRow([]),
  filterExpanded: false,
  sortExpanded: false,
  fieldSelectionExpanded: false,
  exportExpanded: false,
  page: 0,
  sort: {
    on: 'CreateDate',
    dir: 'desc'
  },
  search: '',
  title: '',
  selectedFields: makeSet([
    // default fields for the grid view
    // note these are the names from the GraphQL schema, not the database
    // we only include top level fields, not nested ones
    'TicketNumber',
    'PoNumber',
    'StoreNumber',
    'StoreBuilding',
    'StoreCityStZip',
    'LastCustomerNote',
    'Status',
    'Area',
    'Category',
    'Urgency',
    'DescriptionOfWorkShort',
    'CreateDate'
  ])
};

export default handleActions(
  {
    [types.LIST_CLOSE_PANELS]: state => ({
      ...state,
      filterExpanded: false,
      sortExpanded: false,
      exportExpanded: false,
      fieldSelectionExpanded: false
    }),
    [types.LIST_TOGGLE_FILTER]: state => ({
      ...state,
      // maybe we could remove the empty ones, and just keep one blank one? but, not sure
      filterExpanded: !state.filterExpanded,
      sortExpanded: false,
      exportExpanded: false,
      fieldSelectionExpanded: false
    }),
    [types.LIST_TOGGLE_SORT]: state => ({
      ...state,
      sortExpanded: !state.sortExpanded,
      filterExpanded: false,
      exportExpanded: false,
      fieldSelectionExpanded: false
    }),
    [types.LIST_TOGGLE_FIELDSELECTION]: state => ({
      ...state,
      fieldSelectionExpanded: !state.fieldSelectionExpanded,
      sortExpanded: false,
      filterExpanded: false,
      exportExpanded: false
    }),
    [types.LIST_TOGGLE_EXPORT]: state => ({
      ...state,
      exportExpanded: !state.exportExpanded,
      filterExpanded: false,
      sortExpanded: false,
      fieldSelectionExpanded: false
    }),
    // Set the field for the filter (i.e. the dropdown selection) at the specified index
    [types.LIST_SET_FILTER_FIELD]: (state, {payload: {index, field}}) =>
      update(state, {
        filter:
          !field && state.filter.length > 1
            ? {
                $splice: [[index, 1]]
              }
            : {
                [index]: {$merge: {field}}
              },
        title: {$set: ''},
        page: {$set: 0}
      }),
    // Set the filter value for the given index
    [types.LIST_SET_FILTER_VALUE]: (state, {payload: {index, value}}) =>
      update(state, {
        filter: {[index]: {$merge: {value}}},
        title: {$set: ''},
        page: {$set: 0}
      }),
    // Overwrite the current filter, and optionally the title
    // Used for charts on the dashboard and for the default filter
    [types.LIST_REPLACE_FILTER]: (state, {payload: {filter, title}}) => ({
      ...state,
      filter,
      title: title || state.title
    }),
    // add an empty filter row unless there was already one)
    [types.LIST_ADD_FILTER_ROW]: state => ({
      ...state,
      filter: addEmptyRow(state.filter),
      page: 0
    }),
    [types.LIST_REMOVE_FILTER_ROW]: (state, {payload: {index}}) => ({
      ...state,
      filter: addEmptyRow(update(state.filter, {$splice: [[index, 1]]}))
    }),
    [types.LIST_CLEAR_FILTER]: state => ({
      ...state,
      filter: defaultState.filter,
      page: 0
    }),
    // For saved views
    [types.LIST_SET_VIEW]: (state, {payload: {fields, filter, sort}}) =>
      update(state, {
        selectedFields: {$set: fields || state.selectedFields},
        filter: {$set: filter || state.filter},
        sort: {$set: sort || state.sort},
        page: {$set: 0}
      }),
    // replace sort with the payload
    [types.LIST_SET_SORT]: (state, {payload}) => ({
      ...state,
      sort: payload
    }),
    [types.LIST_SET_PAGE]: (state, {payload}) => ({
      ...state,
      page: payload
    }),
    [types.LIST_SET_SEARCH]: (state, {payload}) => ({
      ...state,
      page: 0,
      search: payload
    }),
    [types.LIST_SELECT_FIELD]: (state, {payload}) =>
      update(state, {
        selectedFields: {
          $merge: {[payload]: 1}
        }
      }),
    [types.LIST_UNSELECT_FIELD]: (state, {payload}) =>
      update(state, {
        selectedFields: {
          $unset: [payload]
        }
      }),
    [types.LIST_UNSELECT_ALL_FIELDS]: state => ({
      ...state,
      selectedFields: {}
    }),
    [types.LIST_SELECT_DEFAULT_FIELDS]: state => ({
      ...state,
      selectedFields: defaultState.selectedFields
    }),
    [types.LIST_SELECT_ALL_FIELDS]: (state, {payload: {allFields}}) => ({
      ...state,
      selectedFields: makeSet(allFields)
    }),
    [ROOT_CLICK]: state => ({
      ...state,
      filterExpanded: false,
      sortExpanded: false,
      exportExpanded: false
    })
  },
  defaultState
);
