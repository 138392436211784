import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import formatDate from '../../utils/formatDate';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import TradeIcon from '../../shared/components/TradeIcon';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 20
  },
  line: {
    backgroundColor: '#acacac',
    height: 1,
    width: '100%'
  },
  ticketBox: {
    display: 'flex',
    padding: '10px',
    cursor: 'pointer',
    alignItems: 'flex-start',
    gap: '10px'
  },
  ticketInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    flexGrow: 1
  },
  ticketDescription: {
    fontWeight: 'bold',
    color: '#0093b2'
  },
  ticketMeta: {
    fontSize: '85%',
    opacity: 0.9
  },
  ticketLink: {
    textDecoration: 'none',
    width: '100%'
  }
}));

const EquipmentTicketsSection = ({tickets}) => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h2>Tickets</h2>
        <div className={classes.line} />
      </div>
      {tickets.length ? (
        tickets.map(ticket => (
          <Link
            key={ticket.id}
            to={`/ticket/${ticket.id}`}
            className={classes.ticketLink}>
            <Ticket data={ticket} classes={classes} />
          </Link>
        ))
      ) : (
        <p>
          <FormattedMessage id="equipments.no_tickets" />
        </p>
      )}
    </section>
  );
};

const Ticket = ({data, classes}) => (
  <div className={classes.ticketBox}>
    <TradeIcon trade={data.Area} color="#e4002b" />
    <div className={classes.ticketInfo}>
      <div className={classes.ticketDescription}>
        {data.TicketNumber} - {data.Category}
      </div>
      <div className={classes.ticketMeta}>
        {formatDate(data.ReceivedDate)} - {data.Status}
      </div>
    </div>
  </div>
);

Ticket.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    TicketNumber: PropTypes.string,
    Area: PropTypes.string,
    Category: PropTypes.string,
    Issue: PropTypes.string,
    Status: PropTypes.string,
    ReceivedDate: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired
};

EquipmentTicketsSection.propTypes = {
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      TicketNumber: PropTypes.string,
      Area: PropTypes.string,
      Category: PropTypes.string,
      Issue: PropTypes.string,
      Status: PropTypes.string,
      ReceivedDate: PropTypes.string
    })
  ).isRequired
};

export default EquipmentTicketsSection;
