import gql from 'graphql-tag';
import reject from 'lodash/reject';
import {isFilterEmpty} from '../shared/utils/filterUtils';
import {PAGE_LENGTH} from '../constants';

export const EQUIPMENT_LIST_VARIABLES = ({
  filter = [],
  sort = [],
  search = '',
  page = 0
}) => ({
  filter: reject(filter, isFilterEmpty),
  sort: sort[0] || {on: 'StoreNumber', dir: 'asc'},
  search,
  offset: page * PAGE_LENGTH,
  limit: PAGE_LENGTH
});

export const EQUIPMENT_LIST_QUERY = fields => gql`
  query EquipmentList(
    $filter: [FilterCriteria]!
    $sort: OrderBy!
    $search: String
    $offset: Int!
    $limit: Int!
  ) {
    equipmentsList(
      filter: $filter
      sort: $sort
      search: $search
      offset: $offset
      limit: $limit
    ) {
      equipments {
        id
        ${fields ||
          `
        Description
        EquipmentType
        Make
        Model
        SerialNumber
        StoreNumber
        StoreAddressCity
        StoreAddressState
        `}
      }
      totalCount
    }
    equipmentsFilterMeta {
      name
      label
      type
      picklist
    }
  }
`;

export const FIELDMETA_QUERY = gql`
  query EquipmentsFieldMeta {
    equipmentsFieldMeta {
      name
      label
      type
    }
    equipmentsDefaultFilter {
      field
      value
    }
  }
`;

export const EQUIPMENT_DETAIL_QUERY = gql`
  query EquipmentDetail($equipmentId: String!) {
    equipment(id: $equipmentId) {
      id
      Description
      EquipmentType
      Make
      Model
      SerialNumber
      StoreNumber
      StoreAddressCity
      StoreAddressState
      Tickets {
        id
        TicketNumber
        Area
        Category
        Issue
        Status
        ReceivedDate
      }
    }
    equipmentsFieldMeta {
      name
      label
      type
    }
  }
`;

export const EQUIPMENT_VIEWS_QUERY = gql`
  query EquipmentsViewQuery {
    equipmentViews {
      name
      definition
    }
  }
`;

export const SAVE_EQUIPMENTVIEW_MUTATION = gql`
  mutation SaveEquipmentView($name: String!, $definition: JSON!) {
    saveEquipmentView(name: $name, definition: $definition) {
      name
      definition
    }
  }
`;

export const DELETE_EQUIPMENTVIEW_MUTATION = gql`
  mutation DeleteEquipmentView($name: String!) {
    deleteEquipmentView(name: $name)
  }
`;
