import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import ApplicationPage from '../../shared/pages/ApplicationPage';
import EquipmentListContainer from '../containers/EquipmentListContainer';

const useStyles = makeStyles(theme => ({
  listContainer: {
    flexGrow: 1
  },
  inner: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1
  }
}));

const VIEWS = {
  'operational': [
    {
      field: 'EquipmentType',
      value: 'Operational'
    }
  ],
  'needs-service': [
    {
      field: 'EquipmentType',
      value: 'Needs Service'
    }
  ]
};

const FullEquipmentList = props => {
  const classes = useStyles();
  const {
    currentUser: {permissions},
    match: {
      params: {view}
    }
  } = props;

  return (
    <ApplicationPage {...props}>
      <div className={classes.inner}>
        <EquipmentListContainer
          className={classes.listContainer}
          querySelectedFields={true}
          currentUser={props.currentUser}
          routeFilter={VIEWS[view]}
        />
      </div>
    </ApplicationPage>
  );
};

export default FullEquipmentList;
