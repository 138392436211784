import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {PositioningContainer} from '../styles';
import ClearButton from '../ClearButton';
import Glyphicon from '../Glyphicon';
import Checkbox from '../inputs/Checkbox';
import SavedView from './SavedView';
// selection of fields on the export panel
const ExportPanel = props => {
  const {
    exportList,
    selectAllFields,
    selectDefaultFields,
    selectField,
    unselectField,
    closePanels,
    selectedFields,
    metadata,
    className,
    scrollingPanelRef,
    title,
    helpText,
    fieldSelectionOnly
  } = props;
  return (
    <PositioningContainer className={className}>
      <div className="panel panel-default panel-save">
        <div className="panel-heading">
          <h3 className="panel-title">{title}</h3>
        </div>
        <ScrollableBody className="panel-body" ref={scrollingPanelRef}>
          <p>{helpText}</p>
          {metadata.map(f =>
            renderFieldCheckbox({
              selectField,
              unselectField,
              selected: !!selectedFields[f.name],
              name: f.name,
              label: f.label
            })
          )}
          <SavedView {...props} />
        </ScrollableBody>
        <div className="panel-footer text-right">
          <ClearButton onClick={selectAllFields}>
            <Glyphicon glyph="list" />
            &nbsp;Select All
          </ClearButton>
          <ClearButton onClick={selectDefaultFields}>
            <Glyphicon glyph="erase" />
            &nbsp;Reset
          </ClearButton>
          {!fieldSelectionOnly && (
            <ClearButton onClick={exportList}>
              <Glyphicon glyph="save" />
              &nbsp;Download...
            </ClearButton>
          )}
          <ClearButton onClick={closePanels}>
            <Glyphicon glyph="remove" />
            &nbsp;Close
          </ClearButton>
        </div>
      </div>
    </PositioningContainer>
  );
};

const renderFieldCheckbox = ({
  selectField,
  unselectField,
  selected,
  name,
  label
}) => (
  <div className="col-md-6 col-xs-12" key={name}>
    <Checkbox
      value={selected}
      label={label}
      onChange={sel =>
        sel.target.checked ? selectField(name) : unselectField(name)
      }
    />
  </div>
);

const ScrollableBody = styled.div`
  max-height: 65vh;
  overflow: auto;
`;

ExportPanel.propTypes = {
  fieldSelectionOnly: PropTypes.bool.isRequired,
  exportList: PropTypes.func.isRequired,
  selectAllFields: PropTypes.func.isRequired,
  selectDefaultFields: PropTypes.func.isRequired,
  selectField: PropTypes.func.isRequired,
  unselectField: PropTypes.func.isRequired,
  closePanels: PropTypes.func.isRequired,
  selectedFields: PropTypes.object.isRequired
};

export default ExportPanel;
